import React from 'react';
import fondoImagen from '../images/estacion-servicio-noche.jpg';

const GasoleoA = () => {
  const containerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${fondoImagen})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    padding: '50px',
    textAlign: 'center',
  };

  return (
    <div className="mb-8 consulta-precios" style={containerStyle}>
      <h2 className="text-orange-500 text-4xl font-bold mb-4">Consulta los Precios de Diesel e+ </h2>
      <p className="text-3xl font-bold mb-4">
        Contáctanos para conocer los mejores precios de gasóleos en toda la provincia de Ciudad Real, incluyendo Diesel e+ (Gasóleo A para vehículos de automoción). Te invitamos a ponerte en contacto con nosotros.
      </p>
      <p className="text-2xl font-bold mb-4">
        Y para Gasóleos B y C, utiliza nuestra calculadora de precios a continuación.
      </p>
    </div>
  );
};

export default GasoleoA;
