import React from 'react';
import waylet_logo from '../images/Logo_Waylet-new-1.png';
import repsol_logo from '../images/Repsol_logo.svg.png';

const TrabajamosCon = () => {
  return (
    <div className="bg-gray-200 py-8">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-semibold mb-8 md:mb-12">Estas marcas garantizan y respaldan nuestra calidad</h2>
        <div className="flex flex-col items-center md:flex-row md:justify-center md:space-x-12">
          <img src={waylet_logo} alt="Logo Waylet" className="max-h-16 mb-4 md:mb-0 lg:mr-32" />
          <img src={repsol_logo} alt="Logo Repsol" className="max-h-16 mb-4 md:mb-0" />
        </div>
      </div>
    </div>
  );
};

export default TrabajamosCon;
