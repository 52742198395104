import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Logo from "../images/logo.png";

const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLinkClick = (sectionId) => {
    // Cerrar el menú al hacer clic en un enlace
    setMenuVisible(false);

    // Scroll suave a la sección correspondiente
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <nav className="p-4 w-full top-0 left-0 z-50 bg-white shadow-md">
      <div className="container mx-auto flex items-center justify-between">
        
          <img
            src={Logo} 
            alt="Logo"  
            className="text-black rounded h-22 w-32 sm:w-22 sm:h-22"
          />
      
        <div className="hidden lg:flex items-center space-x-8">
          <a
            href="#precios"
            className="text-lg text-black hover:text-gray-400"
            onClick={() => handleLinkClick('precios')}
          >
            Precios
          </a>
          <a
            href="#contacto"
            className="text-lg text-black hover:text-gray-400"
            onClick={() => handleLinkClick('contacto')}
          >
            Contacto
          </a>
          <a
            href="#compania"
            className="text-lg text-black hover:text-gray-400"
            onClick={() => handleLinkClick('servicio')}
          >
            Compañía
          </a>
        </div>
        <div className="lg:hidden flex items-center">
          <button
            className="text-xl text-black hover:text-gray-400 focus:outline-none"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </button>
        </div>
        {/* Drawer para dispositivos móviles */}
        <Drawer anchor="right" open={menuVisible} onClose={() => setMenuVisible(false)}>
          <List>
            <ListItem button onClick={() => handleLinkClick('precios')}>
              Precios
            </ListItem>
            <ListItem button onClick={() => handleLinkClick('contacto')}>
              Contacto
            </ListItem>
            <ListItem button onClick={() => handleLinkClick('compania')}>
              Compañía
            </ListItem>
          </List>
        </Drawer>
      </div>
    </nav>
  );
};

export default Navbar;
