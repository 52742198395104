import React from 'react';
import introImage from '../images/thumbnail_IMG_0148.jpg';

const Intro = () => {
  const containerStyle = {
    background: `url(${introImage}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    height: '60vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imageOverlayStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%',
    position: 'absolute',
  };

  const textStyle = {
    zIndex: 1,
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
  };

  return (
    <div className='mb-8' style={containerStyle}>
      <div style={imageOverlayStyle}></div>
      <h2 style={textStyle} className="text-orange-500 text-4xl font-semibold mb-4">Distribuidora Oficial de REPSOL</h2>
      <h1 style={textStyle} className="text-white text-4xl md:text-6xl font-bold mb-8 text-center">Gasóleos Malagón distribuidora Oficial de REPSOL, gasóleos a domicilio agrícola, calefacción y automoción</h1>
    </div>
  );
};

export default Intro;
