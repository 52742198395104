import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-blue-500 text-white py-8 mt-8">
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-4">Contáctanos</h3>
            <p className="mt-2">administracionmalagon@garciaperona.es</p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-4">Teléfono</h3>
            <p className="mt-2">926 80 03 42</p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-4">Déjanos una reseña en Google</h3>
            <a className="mt-2" target="_blank" rel="noreferrer" href='https://g.co/kgs/HNR7SMy'>Pincha Aquí</a>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-4">Ubicación</h3>
            <p className="mt-2"> Calle VALENCIA, 14 BAJO PARCELA 39, 13420 Malagón, Ciudad Real</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
