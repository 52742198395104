import React from 'react';
import aceites from "../images/aceites.jpg"
import legal_depositos from "../images/legal_depositos.jpeg"
import adblue from "../images/adblue.jpg"
import cesión_depositos from "../images/cesión_depositos.jpg"

const ServicioCard = ({ title, description, imageUrl }) => {
  return (
    <div id="servicio" className="border p-4 mb-8 w-96 h-96 rounded-md shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:scale-105 bg-white">
      <div className="card-content h-full flex flex-col justify-between">
        <h3 className="text-2xl font-bold mb-4">{title}</h3>
        {/* Imagen */}
        <img src={imageUrl} alt={title} className="w-full h-48 object-cover mb-4 rounded-md" />
        <p className="text-lg font-bold">{description}</p>
      </div>
    </div>
  );
};

const Servicio = () => {

  return (
    <div id="servicio" className="border p-8 text-center space-y-8 animate__animated animate__fadeIn mb-48" >
      <h2 className="text-4xl font-bold mb-8 text-orange-500">¿Qué otros servicios ofrecemos?</h2>
      <div className="flex justify-around flex-wrap">
        <ServicioCard
          title="Venta y distribución de Adblue"
          description="Para toda la península. Formatos de 5Litros, 10 Litros, 200 Litros, 1000 litros y a granel."
          imageUrl={adblue}
        />
        <ServicioCard
          title="Venta y cesión de depósitos"
          description="Para almacenamiento de gasóleo con homologación y certificado."
          imageUrl={cesión_depositos}
        />
        <ServicioCard
          title="Legalización de depósitos"
          description="En instalaciones. Nos encargamos de todos los trámites necesarios."
          imageUrl={legal_depositos}
        />
        <ServicioCard
          title="Venta y distribución de aceites"
          description="Para todo tipo de vehículos y maquinaria en toda la península. Trabajamos con una agencia externa para garantizar la calidad."
          imageUrl={aceites}
        />
      </div>
    </div>
  );
};


export default Servicio;
