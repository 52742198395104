import React, { useState } from 'react';
import AgricultureTwoToneIcon from '@mui/icons-material/AgricultureTwoTone';
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone';
import agricultura_img from "../images/agricultura.jpeg";
import gasoleo_cas from "../images/image_gasoleo_casa.jpeg";

const GasolineCalculator = () => {
  const [quantity, setQuantity] = useState(0);
  const [gasType, setGasType] = useState('B');
  const [isAgricultureSelected, setIsAgricultureSelected] = useState(true);
  const [priceB, setPriceB] = useState(1.139);
  const [priceC, setPriceC] = useState(1.099);

  const handleSliderChange = (e) => {
    setQuantity(e.target.value);
  };

  const calculatePrice = () => {
    const price = gasType === 'B' ? priceB : priceC;
    return (quantity * price).toFixed(2);
  };

  const gasTypeButtonClick = (type) => {
    setGasType(type);
    setIsAgricultureSelected(type === 'B');
  };

  const containerStyle = {
    background: isAgricultureSelected ? `url(${agricultura_img}) no-repeat center center` : `url(${gasoleo_cas}) no-repeat center center`,
    backgroundSize: isAgricultureSelected ? '100% 100%' : '100% 95%',
  };

  const contentStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  };

  const getDescription = () => {
    const descriptionB = [
      "Gasoil B: El gasoil B mejorado creado a partir de una fórmula avanzada que da como resultado un producto que:",
      "- Alarga la vida útil de tu maquinaria ya que protege el motor de la corrosión y conserva en condiciones adecuadas el sistema de alimentación e inyección del gasóleo agrícola.",
      "- Minimiza los costes de mantenimiento reduciendo la obstrucción de los filtros.",
      "- Ofrece una combustión idónea conservando la potencia, reduciendo las emisiones y el consumo como el primer día.",
      "- Respeta el medioambiente a través de su fórmula de composición sin azufre.",
      "- Innovación, rendimiento y fiabilidad se dan la mano para ofrecerte el mejor gasóleo para tu explotación."
    ].join("\n");

    const descriptionC = `
      Gasoil C: El gasóleo Energy e+ cumple con las exigencias de los fabricantes de calderas, protege los sistemas de alimentación e inyección y permite mantener tu caldera como si fuera nueva. Sus componentes mejorados y exclusivos ofrecen una calidad superior que proporciona a tu hogar el confort, tranquilidad y seguridad que buscas.
    `;

    return gasType === 'B' ? descriptionB : descriptionC;
  };

  return (
    <div id="precios" className="mb-8">
      <h1 className="text-center text-4xl font-bold my-8 text-orange-500">¿Quieres saber el precio de tu Gasoil B o C?</h1>
      <div className='flex flex-col md:flex-row mb-8'>
        <div className="w-full md:w-2/5 mx-auto my-8 border" style={containerStyle}>
          <div className="p-8 rounded-md mx-auto my-4 w-full h-full text-center space-y-4 text-lg md:text-xl lg:text-2xl" style={contentStyle}>
            <h2 className="text-4xl font-bold mb-8 text-black">Calcula el precio de tu gasolina</h2>
            <div className="flex justify-between space-x-4">
              <button
                className={`w-2/5 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800 ${
                  isAgricultureSelected ? 'bg-blue-600' : ''
                }`}
                onClick={() => gasTypeButtonClick('B')}
              >
                <AgricultureTwoToneIcon /> Gasoil B
              </button>
              <button
                className={`w-2/5 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800 ${
                  !isAgricultureSelected ? 'bg-blue-600' : ''
                }`}
                onClick={() => gasTypeButtonClick('C')}
              >
                <CottageTwoToneIcon></CottageTwoToneIcon> Gasoil C
              </button>
            </div>
            <div className="flex items-center mt-4">
              <span className="block text-sm font-medium text-gray-700 mt-8 mb-2">Max 800L</span>
              <input
                type="range"
                min="0"
                max="800"
                value={quantity}
                onChange={handleSliderChange}
                className="w-full mt-1"
              />
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mt-8 mb-6">Cantidad de gasolina (litros)</label>
              <input
                type="number"
                min="0"
                max="800"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="mb-6 w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="flex justify-between text-left">
              <p>Cantidad de gasolina: {quantity} litros</p>
              <p>Precio total: €{calculatePrice()}</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3 mx-auto flex flex-col justify-evenly">
          <h2 className="text-center text-4xl font-bold text-black">¿Por qué somos la mejor elección?</h2>
          <p className="text-xl mx-auto" style={{ whiteSpace: 'pre-line' }}>{getDescription()}</p>
          <p className="text-center text-xl text-red-700 font-bold">Si necesita una cantidad mayor, póngase en contacto con nosotros para incluso mejores precios.</p>
        </div>
      </div>
    </div>
  );
};

export default GasolineCalculator;
