import React from 'react';
import imagenIzquierda from '../images/gasoleos_ubi.png'; // Ruta de la imagen izquierda
import imagenDerecha from '../images/descuento.jpeg'; // Ruta de la imagen derecha

const UbiBanner = () => {
  return (
    <div  id="contacto" className='mb-8 md:mb-12'>
      <h1 className='font-bold text-orange-500 text-2xl md:text-3xl lg:text-4xl text-center mb-20'>
        ¿Dónde estamos y cómo contactarnos?
      </h1>
      <div className="flex flex-col md:flex-row md:justify-center md:items-center justify-center items-center md:space-x-8">
      <a className="w-full lg:mr-20 md:w-1/3 lg:w-1/4 rounded-md mb-20 md:mb-0" href="https://maps.app.goo.gl/NyiFN8FcoueZormY9" target="_blank" rel="noopener noreferrer">
          <img
            src={imagenIzquierda}
            alt="Imagen Izquierda"
          />
        </a>
        <img
          src={imagenDerecha}
          alt="Imagen Derecha"
          className="w-full md:w-1/3 lg:w-1/4 rounded-md"
        />
      </div>
    </div>
  );
};

export default UbiBanner;
