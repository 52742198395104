import React from 'react';
import './App.css';
import Navbar from './components/navBar';
import GasolineCalculator from './components/calculator';

import Footer from './components/Footer';
import TrabajamosCon from './components/trabajamos_con';
import Intro from './components/intro';
import Servicio from './components/Servicio';
import GasoleoA from "./components/gasoleoA";
import UbiBanner from "./components/ubi_banner";


function App() {
  return (
    <div className="app-container">
      <nav className="p-4 w-full top-0 left-0 z-50 bg-white border border-gray shadow-md">
        <div className="container mx-auto flex items-center justify-between">
          <Navbar />
        </div>
      </nav>
      <Intro></Intro>
      <GasolineCalculator />
      <GasoleoA></GasoleoA>
      <Servicio></Servicio>
      <UbiBanner></UbiBanner>
      <TrabajamosCon></TrabajamosCon>
      <Footer />
    </div>
  );
}

export default App;
